/** * 1. 개요 : 가맹점 정보 검색 * 2. 처리내용 : 가맹점 정보 검색 - 집입로 *
File Name : index.vue * date : 2020. 05. 20. 16:15:00 * author : 이지혜 *
history :
*----------------------------------------------------------------------- *
변경일 작성자 변경내용
*----------------------------------------------------------------------- * 2020.
05. 20. 16:15:00 이지혜 최초 작성
*----------------------------------------------------------------------- */
<template>
  <popup :title="title" @close="$emit('close')" popSize="size-grid">
    <div class="search-wrap">
      <collapse-search-pane
        :isSearchPaneShow="isPaSearchPaneShow"
        @fnShowSearchPane="fnShowPaSearchPane"
      >
        <div class="search-area">
          <div id="tblInfo" class="hide">
            {{ "label.merId" | i18n }}, {{ "label.bizNo" | i18n }},
            {{ "label.merNm" | i18n }} &nbsp;{{ "label.search" | i18n }}
          </div>
          <div class="tbl-wrap">
            <table aria-describedby="tblInfo">
              <caption>
                {{
                  "label.searchArea" | i18n
                }}
              </caption>
              <colgroup>
                <col style="width:150px" />
                <col style="width:auto" />
                <col style="width:150px" />
                <col style="width:auto" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <label for="searchMerId">{{ "label.merId" | i18n }}</label>
                  </th>
                  <td>
                    <input-text
                      id="searchMerId"
                      ref="searchMerId"
                      name="searchMerId"
                      v-model="search.merId"
                      :isNumberType="true"
                      @keydown.enter.prevent="validate"
                      :disabled="this.storeUserInfo.grpTypCd == '04' ? true : false"
                    />
                  </td>
                  <th scope="row">
                    <label for="searchBizNo">{{ "label.bizNo" | i18n }}</label>
                  </th>
                  <td>
                    <input-text
                      id="searchBizNo"
                      ref="searchBizNo"
                      name="searchBizNo"
                      v-model="search.bizNo"
                      @keydown.enter.prevent="validate"
                      :disabled="this.storeUserInfo.grpTypCd == '04' ? true : false"
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="searchMerNm">{{ "label.merNm" | i18n }}</label>
                  </th>
                  <td>
                    <input-text
                      id="searchMerNm"
                      v-model="search.merNm"
                      type="text"
                      name="searchMerNm"
                      @keydown.enter.prevent="validate"
                      :disabled="this.storeUserInfo.grpTypCd == '04' ? true : false"
                    />
                  </td>
                  <th scope="row">
                    <label for="searchAgncyCd">{{
                      "label.agncyNm" | i18n
                    }}</label>
                  </th>
                  <td>
                    <input-text
                      id="searchAgncyCd"
                      v-model="search.agncyNm"
                      type="text"
                      name="searchAgncyNm"
                      @keydown.enter.prevent="validate"
                      :disabled="this.storeUserInfo.grpTypCd == '04' ? true : false"
                    />
                  </td>
                </tr>
                <tr v-if="isInquiry && this.storeUserInfo.grpMenuAuthorCd !== 'MEGroup' 
                   && this.storeUserInfo.grpMenuAuthorCd !== 'MEADMIN' 
                   && this.storeUserInfo.grpMenuAuthorCd !== 'MENormal'">
                  <th scope="row">
                    <label for="searchMerGrpNm">{{
                      "label.merGrpNm" | i18n
                    }}</label>
                  </th>
                  <td scope="row">
                    <code-selector
                      id="searchMerGrpNm"
                      ref="searchMerGrpNm"
                      v-model="search.merGrpNm"
                      :isAll="true"
                      :cdList="cdList.merGrpIdList"
                      @change="fnFindMerGrpNm($event.target.value)"
                    />
                    <button
                      
                      type="button"
                      icon-class="search"
                      @click="onSearchMerGrpNm"
                      :disabled="!this.search.merGrpNm"
                    >
                      search
                    </button>
                    
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </collapse-search-pane>
      <div class="search-btn">
        <button type="button" name="searchList" @click.stop="validate">
          {{ "button.look" | i18n }}
        </button>
        <button
          type="button"
          name="clear"
          :disabled="this.storeUserInfo.grpTypCd == '04'"
          @click.stop="clear"
        >
          {{ "button.init" | i18n }}
        </button>
      </div>
    </div>
    <div>
      <data-grid
        ref="dataGrid1"
        :totalItems="gridProps.totalItems"
        :columnDefs="gridProps.columnDefs"
        :rowData="gridProps.rowData"
        :initPage="gridProps.initPage"
        :perPage="pageSize"
        :selectedRows="gridProps.selectedRows"
        :gridHeight="gridProps.gridHeight"
        :rowClassRules="gridProps.rowClassRules"
        :frameworkComponents="gridProps.frameworkComponents"
        :rowSelection="gridProps.rowSelection"
        :topBtnList="gridProps.topBtnList"
        :suppressRowClickSelection="gridProps.suppressRowClickSelection"
        @rowSelected="setRowSelected"
        @selectionChanged="setSelectionChangedRow"
        @callOnRowDoubleClicked="fnRowDoubleClick"
        @fnList="fnList"
      />
    </div>
  </popup>
</template>

<script>
import Popup from "@/components/Popup";
import CollapseSearchPane from "@/components/CollapseSearchPane";
import { InputText } from "@/components/Inputs";

import { EventBus } from "./EventBus";
import { CmnMerInfPopupViewModel, Helper, AnimationHelper } from "./mixins";
import { CodeFetchHelper } from "./mixins";
import { isEmpty } from "@/utils";
import {
  validNumberInputData,
  validAlphabetsAndNumber
} from "@/utils/validate";
import DataGrid from "@/components/DataGrid";

export default {
  name: "CmnMerInfPopup",
  components: {
    Popup,
    CollapseSearchPane,
    InputText,
    DataGrid
  },
  mixins: [CmnMerInfPopupViewModel, Helper, AnimationHelper],
  props: {
    paramData: {
      type: Object,
      default: () => {}
    },
    isInquiry: {
      type: Boolean,
      default: false, // Giá trị mặc định là `false`
    },
    cdList: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      resultList: [],
      merMngVOList: [],

      currPage: 1,
      pageUnit: 100,
      pageSize: 100,
      search: {
        merId: "",
        bizNm: "",
        psnNm: "",
        merNm: "",
        agncyNm: "",
        merGrpNm: "",
        
      },

      gridProps: {
        totalItems: 0,
        columnDefs: [],
        rowData: [],
        // 일반적인 row 선택 or 더블클릭시
        selectedRows: [],
        initPage: true,
        editable: true,
        gridHeight: 350,
        rowClassRules: null,
        frameworkComponents: null,
        rowSelection: null,
        // 로우 선택시 선택이 되도록 하려면 false, 체크박스 or 라디오 선택시 선택이 되오도록 하려면 true
        suppressRowClickSelection: false,
        topBtnList: []
      },
      // 일반적인 row 선택 or 더블클릭시
      selectedRow: null,
      // 체크박스 또는 라디오 row 선택시
      selectedCheckOrRadioRows: [],

      isPaSearchPaneShow: true,
      
    };
  },
  computed: {
    title() {
      return (
        this.i18n("label.mer") +
        this.i18n("label.inf") +
        this.i18n("label.search") // 가맹점 정보 찾기
      );
    }
  },
  watch: {
    // row 더블 클릭 했을 경우 다시 호출
    selectedRow: {
      handler(params) {
        this.setColumnDefs(params);
        this.setRowClassRules();
      }
    }
  },
  created() {
    // stroe 값 가져오기
    this.storeUserInfo = this.$store.getters["Options/userInfo"];
    
  },
  beforeMount() {
    // 그리드 초기화
    this.setColumnDefs();

    // row 클래스 동적 적용
    this.setRowClassRules();

    // 그리드의 frameworkComponents 셋(필요한 경우에만)
    this.setFrameworkComponents();

    // 그리드 상단의 버튼 리스트 정의
    this.setTopBtnList();

    // 그리드의 rowSelection 셋
    this.setRowSelection();
  },
  mounted() {
    // 파람이 있다면 넣음
    // if(!isEmpty(this.paramData.merId)) {
    //   this.search.merId = this.paramData.merId
    // }
    if (!isEmpty(this.paramData)) {
      this.search.bizNo = this.paramData.bizNo;
      this.search.merId = this.paramData.merId;
      this.search.merNm = this.paramData.merNm;
      this.search.merGrpNm = this.paramData.merGrpNm;
      
    }

    // stroe 값 가져오기
    if (this.storeUserInfo.grpTypCd == "04") {
      this.search.agncyCd = this.storeUserInfo.grpCd;
    }
    if (this.storeUserInfo.grpTypCd == "05") {
      this.search.agncyCd = this.storeUserInfo.parGrpCd;
    }

    this.searchList();
  },
  methods: {
    // 가맹점 정보 조회
    searchList() {
      this.resultList = [];
      this.gridProps.rowData = [];

      this.$httpPost(
        "/api/bep/getMerMngList.do",
        {
          searchMerId: this.search.merId,
          searchBizNo: this.search.bizNo,
          searchMerNm: this.search.merNm,
          searchAgncyNm: this.search.agncyNm,
          searchMerGrpNm: this.search.merGrpNm,

          currPage: this.currPage,
          pageUnit: this.pageUnit,
          pageSize: this.pageSize
        },
        {
          showLoading: true
        }
      )
        .then(response => {
          // result 셋
          this.resultList = response.data.resultList;
          // 데이터 총 갯수 셋
          this.gridProps.totalItems = response.data.totalCount;
        })
        .then(() => {
          if (!this.resultList) {
            return;
          } else {
            // 그리드에 조회해온 데이터 셋
            if (this.resultList) {
              this.gridProps.rowData = this.resultList.map(a => ({
                ...a,
                status: "R"
              }));
            }
          }
        })
        .catch(this.commonErrorHandler);
    },
    validate() {
      if (
        this.validateHelper([
          {
            ref: this.$refs.searchMerId,
            isValidate: validNumberInputData(this.search.merId),
            message: this.i18n("alt.validateInputNum")
          },
          // 사업자번호 입력 숫자=>숫자+알파벳  hykim 2023/06/28
          {
            ref: this.$refs.searchBizNo,
            isValidate: validAlphabetsAndNumber(this.search.bizNo),
            message: this.i18n("alt.validAlphabetsAndNumber")
          }
        ])
      ) {
        return;
      } else {
        this.searchList();
      }
    },

    // 페이지 변환시 데이터 조회 함수
    fnList(currPage) {
      this.currPage = currPage;
      this.searchList();
      this.gridProps.initPage = false;
    },

    // 더블 클릭시
    fnRowDoubleClick(selectedRow) {
      this.search.merGrpNm = '';
      
      this.$emit("fnRowDoubleClick", selectedRow, this.search.merGrpNm );
      this.$emit("close");
    },

    // 그리드에 하나의 row 선택 함수
    setSelectionChangedRow(params) {
      this.gridProps.selectedRows = [];
      this.gridProps.selectedRows = [
        { ...params.api.getSelectedRows()[0], status: "U" }
      ];
    },

    // 그리드의 columnDefs 셋
    setColumnDefs(params = {}) {
      this.$nextTick(() => {
        let gridWidth = this.$refs.dataGrid1.$el.clientWidth - 20;
        let totalWidth = 0;

        this.gridProps.columnDefs = this.cmnMerInfPopupViewModel(params).map(
          a => {
            totalWidth += a.width === 0 ? 200 : a.width;

            return {
              headerName: a.headerName,
              field: a.field,
              width:
                a.field === "restCell" && totalWidth > 0
                  ? gridWidth - totalWidth + 200
                  : a.width,
              editable: a.editable,
              pinned: a.pinned,
              cellStyle: a.cellStyle,
              cellClassRules: a.cellClassRules,
              valueFormatter: a.valueFormatter,
              cellRenderer: a.cellRenderer,
              checkboxSelection: a.checkboxSelection,
              suppressMenu: a.suppressMenu,
              sortable: a.sortable
            };
          }
        );
      });
    },

    // 그리드 상단의 버튼 리스트 정의
    setTopBtnList() {},

    // 그리드의 rowClass 셋
    setRowClassRules() {
      this.gridProps.rowClassRules = {
        // 'selected-row': param => this.selectedRow && (this.selectedRow.rowIndex === param.rowIndex)
      };
    },

    // 그리드의 frameworkComponents 셋
    setFrameworkComponents() {
      this.gridProps.frameworkComponents = {};
    },

    // 그리드의 rowSelection 셋
    setRowSelection() {
      // 단수 선택 경우 'single', 복수 선택일 경우 'multiple'
      this.gridProps.rowSelection = "single";
    },

    // 셀렉트박스 또는 라디오 선택시
    setRowSelected(params) {
      if (params.node.isSelected()) {
        this.selectedCheckOrRadioRows.push(params.data);
      } else {
        this.selectedCheckOrRadioRows.pop(params.data);
      }
    },

    validateForDelRow() {
      // 선택한 데이터가 없는 경우 리턴
      if (isEmpty(this.gridProps.selectedRows[0])) {
        this.alert({
          type: "warning",
          title: this.i18n("label.confirm"),
          message: this.i18n("alt.noSelect")
        });
        return;
      } else {
        this.alert({
          title: this.i18n("label.del"),
          message: this.i18n("alt.questionDel"),
          buttons: [
            {
              type: "primary",
              label: this.i18n("button.confirm"),
              onPress: () => this.fnDelRow()
            },
            {
              label: this.i18n("button.cancel")
            }
          ]
        });
      }
    },

    // 입력 폼 초기화
    clear() {
      this.search = {
        agncyCd: "",
        agncyNm: "",
        parDistrCd: "",
        
      };
      this.searchList();
      console.log("1");
      
    },
    fnFindMerGrpNm(value) {
      
      this.search.merGrpNm = value;
      this.search.merNm = '';
      this.search.merId = '';
      this.search.agncyCd = '';
      this.search.bizNm = '';
      this.search.psnNm = '';

      
      
      this.searchList();
    },
    isShowInqPane() {
     
    },
    onSearchMerGrpNm() {
      console.log("mgi:",this.search.merGrpNm);
      this.$emit("onSearchMerGrpNm", this.search);
      this.$emit("close"); 
    },
    hideAll(){
      this.userAuth = this.$store.getters["Options/userInfo"];
      if(this.userAuth.userId = "MEAdmin"){
        
      }
    }

  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/_button.scss";
</style>
