/**
 * 1. 개요 : 시뮬레이션속성정의검색
 * 2. 처리내용 : 시뮬레이션속성정의검색 - 팝업창 컴포넌트
 * @File Name : CmnSmltDefPopup.vue
 * @date : 2020. 04. 07. 15:00:00
 * @author : 이현우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 04. 07. 15:00:00	    이현우	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<popup :title="title" @close="$emit('close')" popSize="size-grid">
		<div class="search-wrap">
			<collapse-search-pane
				:isSearchPaneShow="isPaSearchPaneShow"
				@fnShowSearchPane="fnShowPaSearchPane"
			>
				<div class="search-area">
					<div id="tblInfo" class="hide">
						{{ 'label.corpId' | i18n }}, {{ 'label.merNm' | i18n }}, {{ 'label.agncyCd' | i18n }}, {{ 'label.mappingYn' | i18n }}
						&nbsp;{{ 'label.search' | i18n }}
					</div>
					<div class="tbl-wrap">
						<table aria-describedby="tblInfo">
							<caption>{{ 'label.searchArea' | i18n }}</caption>
							<colgroup>
								<col style="width:150px" />
								<col style="width:auto" />
								<col style="width:150px" />
								<col style="width:auto" />
							</colgroup>
							<tbody>
								<tr>
                <th scope="row">
                  <label for="searchMainClCd">{{ 'label.mainClNm' | i18n }}</label>
                </th>
                <td>
                  <code-selector
                    id="searchMainClCd"
                    ref="searchMainClCd"
                    v-model="search.mainClCd"
                    :cdList="cdList.mainClCdList"
                    :isAll="true"
                    @keydown.enter="onSearchList"
                    @change="fnClCdComboSearch($event)"
                  />
                </td>
                <th scope="row">
                  <label for="searchMidClCd">{{ 'label.midClNm' | i18n }}</label>
                </th>
                <td>
                  <code-selector
                    id="searchMidClCd"
                    ref="searchMidClCd"
                    v-model="search.midClCd"
                    :cdList="cdList.midClCdList"
                    :isAll="true"
                    @keydown.enter="onSearchList"
                    @change="fnClCdComboSearch($event)"
                  />
                </td>
                <th scope="row">
                  <label for="searchSubClCd">{{ 'label.subClNm' | i18n }}</label>
                </th>
                <td>
                  <code-selector
                    id="searchSubClCd"
                    ref="searchSubClCd"
                    v-model="search.subClCd"
                    :cdList="cdList.subClCdList"
                    :isAll="true"
                    @keydown.enter="onSearchList"
                  />
                </td>
              </tr>
							</tbody>
						</table>
					</div>
				</div>
			</collapse-search-pane>
			<div class="search-btn">
				<button type="button" name="searchList" @click.stop="searchList">{{'button.look' | i18n }}</button>
				<button type="button" name="clear" :disabled="this.storeUserInfo.grpTypCd == '04'" @click.stop="clear">{{'button.init' | i18n }}</button>
			</div>
		</div>
		<div>
			<data-grid
				:key="key"
				ref="dataGrid1"
				:totalItems="gridProps.totalItems"
				:columnDefs="gridProps.columnDefs"
				:rowData="gridProps.rowData"
				:initPage="gridProps.initPage"
				:perPage="pageSize"
				:selectedRows="gridProps.selectedRows"
				:gridHeight="gridProps.gridHeight"
				:rowClassRules="gridProps.rowClassRules"
				:frameworkComponents="gridProps.frameworkComponents"
				:rowSelection="gridProps.rowSelection"
				:topBtnList="gridProps.topBtnList"
				:suppressRowClickSelection="gridProps.suppressRowClickSelection"
				@rowSelected="setRowSelected"
				@selectionChanged="setSelectionChangedRow"
				@callOnRowDoubleClicked="fnRowDoubleClick"
				@fnList="fnList"
			/>
		</div>
	</popup>
</template>

<script>
import Popup from "@/components/Popup";
import CollapseSearchPane from "@/components/CollapseSearchPane";
import { InputText } from "@/components/Inputs";

import { EventBus } from "./EventBus";
import { CmnSmltDefPopupViewModel, Helper, AnimationHelper } from "./mixins";
import { isEmpty } from "@/utils";
import DataGrid from "@/components/DataGrid";

export default {
	name: "CmnSmltDefPopup",
	components: {
		Popup,
		CollapseSearchPane,
		InputText,
		DataGrid
	},
	mixins: [CmnSmltDefPopupViewModel, Helper, AnimationHelper],
	props: {
		paramData: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			key: 0,
			resultList: [],
			currPage: 1,
			pageUnit: 100,
			pageSize: 100,
			search: {
				mainClCd: "",
        midClCd: "",
        subClCd: ""
			},

			gridProps: {
				totalItems: 0,
				columnDefs: [],
				rowData: [],
				// 일반적인 row 선택 or 더블클릭시
				selectedRows: [],
				initPage: true,
				editable: true,
				gridHeight: 360,
				rowClassRules: null,
				frameworkComponents: null,
				rowSelection: null,
				// 로우 선택시 선택이 되도록 하려면 false, 체크박스 or 라디오 선택시 선택이 되오도록 하려면 true
				suppressRowClickSelection: false,
				topBtnList: []
			},
			// 일반적인 row 선택 or 더블클릭시
			selectedRow: null,
			// 체크박스 또는 라디오 row 선택시
			selectedCheckOrRadioRows: [],

			isPaSearchPaneShow: true,

			cdList: {
				mainClCdList: [],
        midClCdList: [],
        subClCdList: [],
        hndlClCdList: [],
			}
		};
	},
	computed: {
		/**
     * 화면 title 설정
     * @return {String}
     */
		title() {
			return (
				this.i18n("label.smltDefSearch")
			);
		}
	},
	watch: {
		// row 더블 클릭 했을 경우 다시 호출
		selectedRow: {
			handler(params) {
				this.setColumnDefs(params);
				this.setRowClassRules();
			}
		}
	},
	created() {
		this.fnGetAxcCodeGrpList(["SMLT_MAIN_CL_CD", "SMLT_HNDL_CL_CD"]);
		// stroe 값 가져오기
		this.storeUserInfo = this.$store.getters["Options/userInfo"];
	},
	beforeMount() {
		// 그리드 초기화
		this.setColumnDefs();

		// row 클래스 동적 적용
		this.setRowClassRules();

		// 그리드의 frameworkComponents 셋(필요한 경우에만)
		this.setFrameworkComponents();

		// 그리드의 rowSelection 셋
		this.setRowSelection();
	},
	mounted() {
		this.search.mainClCd = this.paramData.mainClCd;
		this.search.midClCd = this.paramData.midClCd;
		this.search.subClCd = this.paramData.subClCd;

		this.searchList();
	},
	methods: {
		/**
     * 업무 코드 리스트 페치
     * @params {Array} searchCodeGrpIdList
     */
		async fnGetAxcCodeGrpList(searchCodeGrpIdList = []) {
			if (isEmpty(searchCodeGrpIdList)) return;

			await this.$httpPost(
					"/api/bep/getAxcCodeGroupListByCodeGrpIdList.do",
					{ searchCodeGrpIdList },
					{ showLoading: true }
				)
				.then(response => {
					this.cdList.mainClCdList = response.data.resultList.find(a => a[0].codeGrpId === "SMLT_MAIN_CL_CD");
          this.cdList.hndlClCdList = response.data.resultList.find(a => a[0].codeGrpId === "SMLT_HNDL_CL_CD");

				})
				.catch(this.commonErrorHandler);

			this.key = this.key + 1;
		},
		/**
     * 분류코드 콤보 조회
     * @params {Object} search
     */
     async fnClCdComboSearch(event) {

      let params = {};
      let targetId = event.target.id;
      let comboClCd = "";
      if(targetId == "searchMainClCd") {
        params.searchComboClCd = "MID"; // 중분류
        params.searchMainClCd = event.target.value;
        this.cdList.midClCdList = [];
      } else {
        params.searchComboClCd = "SUB"; // 소분류
        params.searchMainClCd = this.search.mainClCd;
        params.searchMidClCd = event.target.value;
        this.cdList.subClCdList = [];
      }

			await this.$httpPost(
					"/api/bep/getSmltDefListForCombo.do", params,
					{
						showLoading: true
					}
				)
				.then(response => {
          // result 셋
          if (response.data.resultList) {
            if(targetId == "searchMainClCd") {
              this.cdList.midClCdList = response.data.resultList;
            } else {
              this.cdList.subClCdList = response.data.resultList;
            }
          }
				})
				.catch(this.commonErrorHandler);
		},
		
		/**
     * 시뮬레이션속성정의 목록 조회
     * @params {Object} search
     */
		searchList(search) {
			if (search) {
				this.search = search;
				this.currPage = 1;
				this.gridProps.initPage = true;
			}

			this.resultList = [];
			this.gridProps.rowData = [];

			this.$httpPost(
					"/api/bep/getSmltDefMngList.do",
					{
						searchMainClCd: this.search.mainClCd,
						searchMidClCd: this.search.midClCd,
						searchSubClCd: this.search.subClCd,
						currPage: this.currPage,
						pageUnit: this.pageUnit,
						pageSize: this.pageSize
					},
					{
						showLoading: true
					}
				)
				.then(response => {
					// result 셋
					this.resultList = response.data.resultList;
					// 데이터 총 갯수 셋
					this.gridProps.totalItems = response.data.totalCount;
				})
				.then(() => {
					// 그리드에 조회해온 데이터 셋
					if (this.resultList)
						this.gridProps.rowData = this.resultList.map(a => ({
							...a,
							status: "R"
						}));
				})
				.catch(this.commonErrorHandler);
		},

		/**
     * 페이지 변환시 데이터 조회 함수
     * @params {int} currPage
     */
		fnList(currPage) {
			this.currPage = currPage;
			this.searchList();
			this.gridProps.initPage = false;
		},

		/**
     * 더블 클릭시
     * @params {Object} selectedRow
     */
		fnRowDoubleClick(selectedRow) {
			this.$emit("fnRowDoubleClick", selectedRow);
			this.$emit("close");
		},

		/**
     * 그리드에 하나의 row 선택 함수
     * @params {Object} params
     */
		setSelectionChangedRow(params) {
			this.gridProps.selectedRows = [];
			this.gridProps.selectedRows = [
				{ ...params.api.getSelectedRows()[0], status: "U" }
			];
		},

		/**
     * 그리드의 columnDefs 셋
     * @params {Object} params
     */
		setColumnDefs(params = {}) {
			this.$nextTick(() => {
				let gridWidth = this.$refs.dataGrid1.$el.clientWidth - 20;
				let totalWidth = 0;

				this.gridProps.columnDefs = this.cmnDefPopupViewModel(params).map(
					a => {
						totalWidth += a.width === 0 ? 200 : a.width;

						return {
							headerName: a.headerName,
							field: a.field,
							width:
								a.field === "restCell" && totalWidth > 0
									? gridWidth - totalWidth + 200
									: a.width,
							editable: a.editable,
							pinned: a.pinned,
							cellStyle: a.cellStyle,
							cellClassRules: a.cellClassRules,
							valueFormatter: a.valueFormatter,
							cellRenderer: a.cellRenderer,
							checkboxSelection: a.checkboxSelection,
							suppressMenu: a.suppressMenu,
							sortable: a.sortable
						};
					}
				);
			});
		},

		/**
     * 그리드의 rowClass 셋
     */
    setRowClassRules() {
      this.gridProps.rowClassRules = {
        // 'selected-row': param => this.selectedRow && (this.selectedRow.rowIndex === param.rowIndex)
      };
    },

    /**
     * 그리드의 frameworkComponents 셋
     */
    setFrameworkComponents() {
      this.gridProps.frameworkComponents = {};
    },

    /**
     * 그리드의 rowSelection 셋
     */
    setRowSelection() {
      // 단수 선택 경우 'single', 복수 선택일 경우 'multiple'
      this.gridProps.rowSelection = "single";
    },

		/**
     * 셀렉트박스 또는 라디오 선택시
     * @params {Object} params
     */
		setRowSelected(params) {
			if (params.node.isSelected()) {
				this.selectedCheckOrRadioRows.push(params.data);
			} else {
				this.selectedCheckOrRadioRows.pop(params.data);
			}
		},
		/**
     * 입력 폼 초기화
     */
		clear() {
			this.search.corpId = "";
			this.search.corpNm = "";
			this.search.corpTypCd = "";
			this.searchList();
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/_button.scss";
</style>


